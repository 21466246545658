import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown'
import { TextField } from 'office-ui-fabric-react/lib/TextField'
import React from 'react'
import { DatePicker } from 'office-ui-fabric-react'
import Button from '@material-ui/core/Button'
import { Grid, Tooltip, IconButton} from '@material-ui/core/'
import GetAppIcon from '@material-ui/icons/GetApp';

class SearchPanel extends React.Component {
  componentDidMount() {
    this.props.store.dispatch({ type: 'searchPanelMounted' })
  }

  updateDatePeriod(ev, item) {
    this.props.store.dispatch({
      type: 'updateDatePeriod',
      datePeriod: item.key,
    })
  }

  updateCustomDate(field, date) {
    this.props.store.dispatch({
      type: 'updateCustomDate',
      field,
      date,
    })
  }

  updateFilter(field, value) {
    this.props.store.dispatch({
      type: 'updateFilter',
      field,
      value,
    })
  }

  trapEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      this.props.store.dispatch({
        type: 'triggerSearch',
        filters: this.props.state,
      })
    }
    return e
  }

  render() {
    return (
      <div onKeyUp={(e) => this.trapEnter(e)}>
        <Grid container spacing={2} style={{ padding: '16px 32px' }}>
          <Grid item xs={6} sm={6} md={2} >
            <TextField
              label="Page name"
              description="*Enter comma-separated values"
              type="string"
              name="pageName"
              value={this.props.state.pageName}
              onChange={(e) => this.updateFilter('pageName', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} >
            <TextField
              label="Company name"
              description="*Enter comma-separated values"
              type="string"
              name="companyName"
              value={this.props.state.companyName}
              onChange={(e) => this.updateFilter('companyName', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} >
            <TextField
              label="Search on text"
              description="*Enter comma-separated values"
              name="header"
              value={this.props.state.header}
              onChange={(e) => this.updateFilter('header', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <TextField
              label="Search by domain"
              description="*Enter comma-separated values"
              name="promotedUrl"
              value={this.props.state.promotedUrl}
              onChange={(e) => this.updateFilter('promotedUrl', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <Dropdown
              label="Time frame"
              options={[
                { key: 'TODAY', text: 'Today' },
                { key: 'YESTERDAY', text: 'Yesterday' },
                { key: 'LAST_7_DAYS', text: 'Last 7 days' },
                { key: 'LAST_14_DAYS', text: 'Last 14 days' },
                { key: 'LAST_30_DAYS', text: 'Last 30 days' },
                { key: 'THIS_MONTH', text: 'This month' },
                { key: 'LAST_MONTH', text: 'Last month' },
                { key: 'THIS_YEAR', text: 'This year' },
                { key: 'ALL_TIME', text: 'All time' },
                { key: 'CUSTOM', text: 'Custom' },
              ]}
              selectedKey={this.props.state.datePeriod}
              onChange={this.updateDatePeriod.bind(this)}
            />
            {this.props.state.datePeriod === 'CUSTOM' && (
              <>
                <DatePicker
                  label="from"
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  value={this.props.state.startDate && this.props.state.startDate.toDate()}
                  onSelectDate={(d) => this.updateCustomDate('startDate', d)}
                />

                <DatePicker
                  label="to"
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  value={this.props.state.endDate && this.props.state.endDate.toDate()}
                  onSelectDate={(d) => this.updateCustomDate('endDate', d)}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} align='left'>

            <Button
              color="primary"
              variant="contained"
              style={{ marginRight: 5 }}
              onClick={() =>
                this.props.store.dispatch({
                  type: 'triggerSearch',
                  filters: this.props.state,
                })
              }
            >
              Search
            </Button>
              <Tooltip title={'Download result to xlsx'}>
                  <IconButton
                      color="default"
                      variant="outlined"
                      onClick={(e) => {
                          this.props.store.dispatch({ type: 'saveToFile', e })
                      }}
                  >
                      <GetAppIcon/>

                  </IconButton>
              </Tooltip>
              <Button
                  style={{ marginRight: 5 }}
                  color="default"
                  variant="outlined"
                  onClick={(e) => {
                      this.props.store.dispatch({ type: 'copyLinkToClipboard', e })
                  }}
              >
                  Copy image links
              </Button>
              <Button
                  color="default"
                  variant="outlined"
                  onClick={(e) => {
                      this.props.store.dispatch({ type: 'createCampaign', e })
                  }}
              >
                  Duplicate
              </Button>
          </Grid>

            <div className="clearfix"></div>
        </Grid>
      </div>
    )
  }
}

export default SearchPanel
