import decode from 'jwt-decode'
import auth0 from 'auth0-js'

const ID_TOKEN_KEY = 'id_token'
const ACCESS_TOKEN_KEY = 'access_token'
const USER_OPTIONS_KEY = 'user_options'

const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID
const CLIENT_DOMAIN = process.env.REACT_APP_AUTH0_SHORT_DOMAIN
const REDIRECT = process.env.REACT_APP_AUTH0_REDIRECT
const SCOPE = process.env.REACT_APP_AUTH0_SCOPE
const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE


let auth = new auth0.WebAuth({
  clientID: CLIENT_ID,
  domain: CLIENT_DOMAIN,
})

export function login() {
  auth.authorize({
    responseType: 'token id_token',
    redirectUri: REDIRECT,
    audience: AUDIENCE,
    scope: SCOPE,
  })
}

export function logout() {
  clearIdToken()
  clearAccessToken()
  clearUserOptions()
}

export function requireAuth(nextState, replace) {
  if (!isLoggedIn()) {
    replace({ pathname: '/' })
  }
}

export function getIdToken() {
  return localStorage.getItem(ID_TOKEN_KEY)
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
}

export function clearIdToken() {
  return localStorage.removeItem(ID_TOKEN_KEY)
}

export function clearAccessToken() {
  return localStorage.removeItem(ACCESS_TOKEN_KEY)
}

export function clearUserOptions() {
  return localStorage.removeItem(USER_OPTIONS_KEY)
}

function getParameterByName(name) {
  let match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

export function setAccessToken() {
  let accessToken = getParameterByName('access_token')
  if (accessToken !== null) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  }
}

export function setIdToken() {
  let idToken = getParameterByName('id_token')
  if (idToken !== null) {
    localStorage.setItem(ID_TOKEN_KEY, idToken)
  }
}

export async function getUserInfo() {
  const endpoint = '/api/userinfo'
  const response = await fetch(endpoint, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  })
  return await response.json()
}

export async function setUserOptions() {
  const userInfo = await getUserInfo()
  localStorage.setItem(USER_OPTIONS_KEY, JSON.stringify(userInfo))
}


export function getUserOptions() {
  const json = localStorage.getItem(USER_OPTIONS_KEY) || '{}'
  return JSON.parse(json).app_metadata || {}
}

export function isLoggedIn() {
  const idToken = getIdToken()
  return !!idToken && !isTokenExpired(idToken)
}

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken)
  if (!token.exp) {
    return null
  }

  const date = new Date(0)
  date.setUTCSeconds(token.exp)

  return date
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token)
  return expirationDate < new Date()
}
