
import produce from 'immer'
import moment from 'moment-timezone'
import qs from 'qs'


function getDatePeriod(value) {
  console.log(value)
  switch (value) {
    case 'TODAY':
      return {
        startDate: moment().tz('est').startOf('day'),
        endDate: moment().tz('est').endOf('day')
      }

    case 'YESTERDAY':
      return {
        startDate: moment().tz('est').add(-1, 'days').startOf('day'),
        endDate: moment().tz('est').add(-1, 'days').endOf('day'),
      }
    case 'LAST_7_DAYS':
      return {
        startDate: moment().tz('est').add(-7, 'days').startOf('day'),
        endDate: moment().tz('est').endOf('day')
      }
    case 'LAST_14_DAYS':
      return {
        startDate: moment().tz('est').add(-14, 'days').startOf('day'),
        endDate: moment().tz('est').endOf('day'),
      }
    case 'LAST_30_DAYS':
      return {
        startDate: moment().tz('est').add(-30, 'days').startOf('day'),
        endDate: moment().tz('est').endOf('day')
      }
    case 'THIS_MONTH':
      return {
        startDate: moment().tz('est').startOf('month').startOf('day'),
        endDate: moment().tz('est').endOf('day')
      }
    case 'LAST_MONTH':
      return {
        startDate: moment().tz('est').add(-1, 'months').startOf('month').startOf('day'),
        endDate: moment().tz('est').add(-1, 'months').endOf('month').endOf('day')
      }
    case 'THIS_YEAR':
      return {
        startDate: moment().tz('est').startOf('year').startOf('month').startOf('day'),
        endDate: moment().tz('est').endOf('day')
      }
      case 'ALL_TIME':
        return {
        startDate: moment().tz('est').add(-10, 'year'),
          endDate: moment().tz('est').endOf('day')
        }
    case 'CUSTOM':
      return {  }
    default:
      throw new Error('invalid date range')
  }
}


export function setStateFromQS(state, draft) {
  if (window.location.pathname !== '/library')
    return 0

  const queryParams = qs.parse(window.location.search.replace('?', ''))

  if (queryParams.pageName) {
    draft.pageName = queryParams.pageName
  }
  if (queryParams.companyName) {
    draft.companyName = queryParams.companyName
  }
  if (queryParams.header) {
    draft.header = queryParams.header
  }
  if (queryParams.promotedUrl) {
    draft.promotedUrl = queryParams.promotedUrl
  }
  if (queryParams.sortField) {
    draft.sortField = queryParams.sortField
  }
  if (queryParams.isSortAsc) {
    draft.isSortAsc = queryParams.isSortAsc
  }
  if (queryParams.datePeriod) {
    draft.datePeriod = queryParams.datePeriod
  }
  if (queryParams.startDate) {
    draft.startDate = moment(queryParams.startDate)
  }
  if (queryParams.endDate) {
    draft.endDate = moment(queryParams.endDate)
  }
  return (Object.entries(queryParams).length > 0 )
}

const DefaultFiltersState = {
  pageName: '',
  companyName: '',
  header: '',
  promotedUrl: '',
  sortField: 'duplicates',
  isSortAsc: false,
  datePeriod: 'THIS_YEAR',
  startDate: moment().tz('est').startOf('year').startOf('month').startOf('day'),
  endDate: moment().tz('est').endOf('day')
}

export function filtersReducer(state = DefaultFiltersState, event) {
  switch (event.type) {
    case 'updateFilter': {
      const { field, value } = event
      return produce(state, (draft) => {
        draft[field] = value
      })
    }
    case 'searchPanelMounted':
      return state
    case 'gotBusinesses':
      return produce(state, (draft) => {
        draft.businesses = event.xs
      })
    case 'updateDatePeriod':
      return produce(state, (draft) => {
        const { startDate, endDate } = getDatePeriod(event.datePeriod)
        draft.datePeriod = event.datePeriod
        if (startDate && endDate) {
          draft.startDate = startDate
          draft.endDate = endDate
        }

      })
    case 'updateCustomDate':
      return produce(state, (draft) => {
        draft[event.field] = moment(event.date)
      })
    case 'initFromQS': {
      const ns = produce(state, (draft) => {
        const isModified = setStateFromQS(state, draft)
        if (isModified) {
          setTimeout(() => event.store.dispatch({
            type: 'triggerSearch',
            filters: ns,
            // sortField: 'duplicates',
            // isSortAsc: false
          }), 10)
        }
      })


      return ns;
    }
    case 'triggerSearch': {
      window.history.pushState({}, '', buildUrl(state))
      return state
    }
    default:
      return state
  }
}

function buildUrl(state) {

  const {
    pageName,
    companyName,
    header,
    promotedUrl,
    sortField,
    isSortAsc,
    datePeriod,
    startDate,
    endDate
  } = state
  return `/library?pageName=${pageName}&companyName=${companyName}&header=${header}&promotedUrl=${promotedUrl}&sortField=${sortField}&isSortAsc=${isSortAsc}&datePeriod=${datePeriod}&startDate=${startDate&&startDate.format('YYYY-MM-DD')}&endDate=${endDate&&endDate.format('YYYY-MM-DD')}`
}
