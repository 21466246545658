import { Component } from 'react';
import { setIdToken, setUserOptions, setAccessToken } from './AuthService';

class Callback extends Component {
    async componentDidMount() {
        setAccessToken();
        setIdToken();
        await setUserOptions();
        window.location.href = '/';
    }

    render() {
        return null;
    }
}

export default Callback;
