import { initializeIcons } from '@uifabric/icons'
import { Spinner, SpinnerSize } from 'office-ui-fabric-react'
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator'
import { Separator } from 'office-ui-fabric-react/lib/Separator'
import { registerIcons } from 'office-ui-fabric-react/lib/Styling'
import React from 'react'
import * as BrandIcons from './BrandIcons'
import store from './Store'
import ResultsPanel from './ResultsPanel'
import SearchPanel from './SearchPanel'

class Insights extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.getState()
    store.subscribe(() => this.setState(store.getState()))

    initializeIcons()
    registerIcons({
      icons: {
        facebook: BrandIcons.facebookSvg,
        twitter: BrandIcons.twitterSvg,
        taboola: BrandIcons.taboolaIcon,
        gemini: BrandIcons.geminiIcon,
        dnd: BrandIcons.dndSvg,
        baidu:BrandIcons.baiduIcon,
        notFound: BrandIcons.notFoundIcon,
      },
    })
  }

  render() {
    return (
      <div>
        <Separator />
        <SearchPanel state={this.state.filters} store={store} />
        {this.state.results.loading ? <ProgressIndicator /> : <Separator />}
        <label style={{ padding: '0px 32px' }}>
          <b>{this.state.results.info}</b>
        </label>
        <div className="search-result">
          {this.state.results.loading && <Spinner label="loading..." size={SpinnerSize.large} />}
          {this.state.results.items.length > 0 ? <ResultsPanel state={this.state.results} store={store} /> : null}
        </div>
      </div>
    )
  }
}

export default Insights
