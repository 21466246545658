
const DefaultSettings = {
  createButtonPlatform: 'taboola'
}

export function getUserSettings() {
  const opts = localStorage.getItem('fb-ads-user-opts')
  if (opts === null) {
    return DefaultSettings
  } else {
    return JSON.parse(opts)
  }
}

export function setUserSettings(opts) {
  localStorage.setItem('fb-ads-user-opts', JSON.stringify(opts))
}

export default { getUserSettings, setUserSettings }