/* eslint-disable react/display-name */
import React from 'react'
import { filterAds, getATData } from '../Api'
import store from './Store'
import { Icon } from '@fluentui/react/lib/Icon'
import downloadFile from 'js-file-download'
import moment from 'moment-timezone'
import bluebird from 'bluebird'
import {CommandBarButton, Selection, Link, Image, TooltipHost} from 'office-ui-fabric-react'
import userSettings from '../utils/UserSettings'
import {ImageFit} from "office-ui-fabric-react/lib/Image";
import { Button } from '@material-ui/core'
import * as xlsx from "xlsx";


function getOverviewUrl(name) {
  const state = store.getState()
  const startDate = moment(state.filters.startDate).format('YYYY-MM-DD')
  const endDate = moment(state.filters.endDate).add(1, 'day').format('YYYY-MM-DD')
  const date = `${startDate}_${endDate}`
  return `https://sms.shinez.io/overview?ad_name=${name}&ad_status=&campaign_note=&prefix=&est_datetime=${date}&total_spend=&total_revs=&roas=&days_running=&daily_cap=&lifetime_budget=&bid_amount=&total_profit=&cpc=&conversions_cost=&pps=&disc=&campaign_analysts=&changed=&bid_strategy=&cap_usage=&site=&article_code=&country_code=&device_code=&account_code=&estimated_epc_ios=&estimated_epc_android=&is_internal=any&objective=&tags=&creative_id=&platform=&creator=&sort=total_spend&order=descend&page_id=`
}
const CreatePlatforms = [
  {
    key: 'taboola',
    text: 'Taboola',
    shortText: 'TB',
  },
  {
    key: 'revcontent',
    text: 'Revcontent',
    shortText: 'RC',
  },
  {
    key: 'gemini',
    text: 'Gemini',
    shortText: 'GM',
  },
  {
    key: 'facebook',
    text: 'Facebook',
    shortText: 'FB',
  },
  {
    key: 'outbrain',
    text: 'Outbrain',
    shortText: 'OB',
  },
  {
    key: 'twitter',
    text: 'Twitter',
    shortText: 'TW',
  },
  {
    key: 'baidu',
    text: 'Baidu',
    shortText: 'BD',
  },
  {
    key: 'google_ads',
    text: 'Google_Ads',
    shortText: 'GA',
  }
]

const withStatColumnDefaults = (properties, drop) => {
  // Populate common properties for stat columns
  //
  // Arguments:
  //
  //   properties - column-specific properties; mandatory: "name", "fieldName";
  //                "key" is set equal to "fieldName" if missing
  //
  //   drop - list of default property names that shouldn't be added; currently
  //          is used to remove "data" and "getKey" from columls that didn't
  //          have these properties prior to factoring out the defaults
  //          (perhaps it's not that important, but let's be cautious and
  //          preserve the exact behavior; though feel free to clean up if
  //          you're sure what you're doing)
  const key = properties.key || properties.fieldName;
  const withDefaults = {
    key: key,
    minWidth: 60,
    maxWidth: 80,
    title: 'some',
    isResizable: true,
    onRender: (item) => {
      const isExpanded = item.isExpanded || false;

      if (key === 'name') {

        const handleExpandClick = () => {
          item.isExpanded = !isExpanded;
          store.dispatch({ type: 'onColumnExpand', columnKey: key });
        };

        return (
          <div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Link href={getOverviewUrl(item[key][0])} target="_blank" rel="noopener noreferrer">
                <div style={{ fontSize: '1.0em', fontWeight: '400', padding: '8px 0px', lineHeight: 1.8 }}>
                  {item[key][0]}
                </div>
              </Link>
              {item[key].length > 1 && (
                <Button size='small' color='primary' style={{width: '25px'}} variant='text' onClick={handleExpandClick}>
                  <TooltipHost content={isExpanded ? 'Collapse' : 'Expand'} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
                    {isExpanded ? '▲' : '▼'}
                  </TooltipHost>
                </Button>
              )}
            </div>
            {isExpanded && (
              <div>
                {item[key].slice(1).map((name, i) => (
                  <Link key={name + i} href={getOverviewUrl(name)} target="_blank" rel="noopener noreferrer">
                    <div style={{ fontSize: '1.0em', fontWeight: '400', padding: '8px 0px', lineHeight: 1.8 }}>
                      {name}
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        );
      }

      return (
        <div style={{ position: 'relative' }}>
          <TooltipHost content={properties.name} calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
            <div style={{ fontSize: '1.0em', fontWeight: '600', padding: '8px 0px', lineHeight: 1.8 }}>
              {numberFormat.format(item[key][0])} {properties.markSign}
            </div>
          </TooltipHost>
          {isExpanded && (
            <div>
              {item[key].slice(1).map((x, i) => (
                <TooltipHost key={i} content={properties.name} calloutProps={{ gapSpace: 0 }} >
                  <div key={i} style={{ fontSize: '1.0em', fontWeight: '600', padding: '8px 0px', lineHeight: 1.8 }}>
                    {numberFormat.format(x)} {properties.markSign}
                  </div>
                </TooltipHost>
              ))}
            </div>
          )}
        </div>
      );
    },
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),
    getValueKey: (item) => item[key],
    ...properties
  };

  (drop || []).forEach((key) => delete withDefaults[key]);
  return withDefaults;
};

const _columns = [
  {
    name: 'Platform',
    fieldName: 'platform',
    key: 'platform',

    onRender: (item) => {
      let iconName = item.platform[0]
      if (!iconName || !_knownPlatforms.has(iconName.toLowerCase())) {
        iconName = 'notFound'
      } else {
        iconName = item.platform[0].toLowerCase()
      }
      if (iconName === 'notFound') {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <Icon iconName={iconName} className="greenIcon" />
            <p>unknown</p>
          </div>
        )
      }
      return <div style={{ display: 'flex', justifyContent: 'center' }}><Icon iconName={iconName} className="greenIcon" /></div>
    },
    minWidth: 64,
    maxWidth: 64,
  },
  {
    name: 'Duplicate via SPM',
    fieldName: 'create_button',
    key: 'create_button',
    isResizable: false,
    onRender: (item) => (
      <div style={{ height: '48px', display: 'flex', justifyContent: 'center' }}>
        <CommandBarButton
          text={CreatePlatforms.find((x) => x.key === store.getState().results.createButtonPlatform).shortText}
          iconProps={{ iconName: 'Add' }}
          split
          menuProps={{
            items: CreatePlatforms.filter((x) => x.key !== store.getState().results.createButtonPlatform),
            isBeakVisible: false,
            onItemClick: (e, b) => {
              item.createButtonPlatform = b.key
              store.dispatch({ type: 'onChangeCreatePlatform', createButtonPlatform: b.key })
            },
          }}
          onClick={() => {
            store.dispatch({ type: 'createCampaign', item })
          }}
        />
      </div>
    ),
    minWidth: 135,
    maxWidth: 135,
  },
  {
    name: 'Ad name',
    fieldName: 'name',
    key: 'name',
    minWidth: 340,
    maxWidth: 350,
    isResizable: true,
    data: 'string',
    isMultiline: true,
    onColumnClick: (ev, item) => store.dispatch({ type: 'onColumnClick', item }),
    onRender: (item) => (
      withStatColumnDefaults({
        name: 'Ad name',
        fieldName: 'name',
      }, ['data', 'getKey']).onRender(item)
    ),
  },
  {
    name: 'Image',
    fieldName: 'image_url',
    key: 'image_url',
    minWidth: 150,
    maxWidth: 150,
    onRender: (item) => renderImageCell(item),
  },
  {
    name: 'Text',
    fieldName: 'text',
    key: 'text',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    data: 'string',
    onRender: (item) => renderTextCell(item.text),
    isMultiline: true,
  },
  {
    name: 'Title',
    fieldName: 'title',
    key: 'title',
    minWidth: 100,
    maxWidth: 160,
    isResizable: true,
    data: 'string',
    isMultiline: true,
    onRender: (item) => renderTextCell(item.title)
  },
  // Stat columns
  withStatColumnDefaults({
    name: 'LT Clicks',
    fieldName: 'clicks',
    minWidth: 80,
  }, ['data', 'getKey']),
  withStatColumnDefaults({
    name: 'LT Spend',
    fieldName: 'spend',
    minWidth: 80,
    markSign: '$'
  }),
  withStatColumnDefaults({
    name: 'Clicks',
    fieldName: 'clicks',  // XXX: it this correct (same as "LT Ad Clicks")?
    key: 'atclicks',
  }, ['getKey']),
  withStatColumnDefaults({
    name: 'CTR',
    fieldName: 'atctr',
    markSign: '%'
  }, ['getKey']),
  withStatColumnDefaults({
    name: 'Spend',
    fieldName: 'atspend',
    isSorted: true,
    isSortedDescending: true,
    minWidth: 70,
    markSign: '$'
  }),
  withStatColumnDefaults({
    name: 'CPC',
    fieldName: 'cpc',
  }, ['getKey']),
  // End of Stat columns
]

function triggerSearch(filters, sortField, isSortAsc) {
  const numericFilters = [];
  const rangeFilters = [];
  const wildFilters = [filters.ad, filters.title, filters.text]
  const platformFilters = [filters.platform]
  const _sortField = sortField.match(/^at.*/) ? sortField.slice(2) : sortField
  const payload = {
    prefix: filters.prefix.value,
    numericFilters: numericFilters,
    rangeFilters: rangeFilters,
    wildFilters: wildFilters,
    platformFilters:platformFilters,
    startDate: filters.startDate.format('YYYY-MM-DD'),
    endDate: filters.endDate.format('YYYY-MM-DD'),
    sortField: _sortField,
    isSortAsc,
  }

  return filterAds(payload)
    .then((xs) =>
      getActiveTimeStats(
        xs,
        filters,
        filters.startDate.format('YYYY-MM-DD'),
        filters.endDate.format('YYYY-MM-DD'),
        sortField,
        isSortAsc
      )
    )
    .then((res) => store.dispatch({ type: 'gotSearchResults', res }))
    .catch((err) => store.dispatch({ type: 'searchError', err }))
}

const numberFormat = new Intl.NumberFormat('en-US', {maximumFractionDigits: 1})
const _knownPlatforms = new Set(['facebook', 'taboola', 'twitter', 'gemini', 'baidu', 'outbrain'])

function _onColumnClick(column, columns) {
  const newColumns = columns.slice()
  const currColumn = newColumns.filter((currCol) => column.key === currCol.key)[0]
  newColumns.forEach((newCol) => {
    if (newCol === currColumn) {
      currColumn.isSortedDescending = !currColumn.isSortedDescending
      currColumn.isSorted = true
    } else {
      newCol.isSorted = false
      newCol.isSortedDescending = true
    }
  })

  return { columns: newColumns, sortField: currColumn.key, isSortAsc: !currColumn.isSortedDescending }
}

function extractImageNameFromUrl(url) {
  const UNKNOWN = 'unknown.jpg'
  let end = url.indexOf('?')
  if (end === -1) {
    end = url.length
  }
  const begin = url.lastIndexOf('/', end - 1)
  if (begin === -1) {
    return UNKNOWN
  } else {
    let u = url.substring(begin + 1, end)
    if (!u) {
      return UNKNOWN
    } else if (!u.match(/.*\.jpg/i) && !u.match(/.*\.png/i)) {
      return u + '.jpg'
    } else {
      return u
    }
    // return url.substring(begin + 1, end)
  }
}

function download(url) {
  const downloadOne = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((bl) => {
        downloadFile(bl, extractImageNameFromUrl(url))
      })
      .catch((err) => {
        console.log("Can't download file" + err.message)
      })
  }
  if (Array.isArray(url)) {
    url.forEach(downloadOne)
  } else {
    downloadOne(url)
  }
}

function renderImageCell(item) {
  const displayOneImage = (url,i) => {
    return (
      <div key={url + i} className="image-preview">
        <a href={url} rel="noopener noreferrer" target="_blank">
          <Image src={url} alt={item.name} imageFit={ImageFit.contain} height="150px"
            onClick={() => download(url)}
          />
        </a> 
      </div>
    )
  }
  const displayImages = (urls) => (urls.map((url,i) => displayOneImage(url, i)))
  return displayImages(item.image_url)
}

function renderTextCell(item) {
    const displayOneText = (text,i) => {
        return (
            <div key={text + i}>{text}</div>
        )
    }
    const displayTexts = (texts) => (<div>{texts.map((item, idx) => displayOneText(item, idx))}</div>)
    return displayTexts(item)
}

function chunk(xs, chunk_size) {
  const res = []
  let cur = []
  for (let i = 0; i < xs.length; i++) {
    if (cur.length === chunk_size) {
      res.push(cur)
      cur = []
    }
    cur.push(xs[i])
  }
  if (cur.length) {
    res.push(cur)
  }
  return res
}

async function getActiveTimeStats(xxs, filters, startDate, endDate, sortField, isSortAsc) {
  const set = new Set()
  const xs = xxs.filter((x) => {
    if (set.has(x.name)) {
      return false
    } else {
      set.add(x.name)
      return true
    }
  })
  const ys = chunk(xs, 120)

  const rs = (
    await bluebird.map(
      ys,
      async (as) => {
        try {
          console.info(`getting atdata`)

          const s = await getATData(as
            .map((a) => a.name).join(','), startDate, endDate)
          const stats = sumStats(s.data)
          return as.map((a) => {
            if (stats[a.name]) {
              return { ...a, ...stats[a.name] }
            }
            return { ...a, atspend: 0, atclicks: 0, atctr: 0 }
          })
        } catch (e) {
          console.warn(`can't get stat`, e)
          return []
        }
      },
      { concurrency: 1 }
    )
  ).flat()

  const rs1 = collapseSimilarAds(rs, sortField, isSortAsc)

  const rs2 = rs1
    .filter(x =>
      x.atspend.some((_, i) =>
        (filters.spend.value.from ? x.atspend?.[i] >= filters.spend.value.from : true) //&&
        // (filters.spend.value.to ? x.atspend?.[i] <= filters.spend.value.to : true) &&
        // (filters.ctr.value.from ? x.atctr?.[i] >= filters.ctr.value.from : true) &&
        // (filters.ctr.value.to ? x.atctr?.[i] <= filters.ctr.value.to : true)
        )
    )


  // if (sortField === 'atctr' || sortField === 'atspend' || sortField === 'atclicks') {
  let xs1 = rs2.sort((x, y) => (y[sortField][0] - x[sortField][0])); //got desc result
  if (isSortAsc) {
    xs1 = xs1.reverse();
  }
  return xs1
}

// ({atspend: 0, atctr: 0, atclicks: 0})
function sumStats(stats) {
  const st = {}
  if (stats && stats.length > 0) {
    stats.forEach(({ c, e, k, i, m }) => {
      if (st[c]) {
        const { e: e0, k: k0, i: i0, m: m0 } = st[c]
        st[c] = { e: parseFloat(e) + e0, k: parseFloat(k) + k0, i: parseFloat(i) + i0, m: parseFloat(m) + m0 }
      } else {
        st[c] = { e: parseFloat(e), k: parseFloat(k), i: parseFloat(i), m: parseFloat(m) }
      }
    })
  }

  return Object.fromEntries(
    Object.entries(st).map(([c, { e, k, m }]) => [c, {
      atspend: e,
      atctr: m ? (100 * k) / m : 0,
      atclicks: k,
      cpc: e / k
    }])
  )
}


function _swap(x, field, i, j) {
  const tmp = x[field][i]
  x[field][i] = x[field][j]
  x[field][j] = tmp;
}

function swap(x, i, j) {
  _swap(x, 'atspend', i, j)
  _swap(x, 'atclicks', i, j)
  _swap(x, 'atctr', i, j)
  _swap(x, 'clicks', i, j)
  _swap(x, 'ctr', i, j)
  _swap(x, 'imps', i, j)
  _swap(x, 'name', i, j)
  _swap(x, 'spend', i, j)
  _swap(x, 'platform', i, j)
  _swap(x, 'cpc', i, j)
}

function sortInside(x, sortField) {

  let i = 1
  while (i < x[sortField].length) {
    let j = i
    while (j > 0 && x[sortField][j - 1] < x[sortField][j]) {
      swap(x, j, j - 1)
      j--;
    }
    i++;
  }

  return x;
}

function collapseSimilarAds(xs, sortField, isSortAsc) {

  const kf = (x) => x.name[0].slice(0, 2) + '&' + x.title[0] + '&' + x.image_url[0] + "&" + x.text[0]
  const f = x => ({
    ...x,
    atspend: [x.atspend],
    atclicks: [x.atclicks],
    atctr: [x.atctr],
    clicks: [x.clicks],
    ctr: [x.ctr],
    cpc: [x.cpc],
    imps: [x.imps],
    name: [x.name],
    spend: [x.spend],
    link: x.link,
    platform: [x.platform],
    title: Array.isArray(x.title) ? x.title : [x.title],
    image_url:x.s3ImageUrl? (Array.isArray(x.s3ImageUrl) ? x.s3ImageUrl : [x.s3ImageUrl]): (Array.isArray(x.image_url) ? x.image_url : [x.image_url]),
    text: Array.isArray(x.text) ? x.text : [x.text]
  })

  const g = (x1, x2) =>  {
      return ({
    atclicks: x1.atclicks.concat(x2.atclicks)
    , atctr: x1.atctr.concat(x2.atctr)
    , atspend: x1.atspend.concat(x2.atspend)
    , clicks: x1.clicks.concat(x2.clicks)
    , ctr: x1.ctr.concat(x2.ctr)
    , cpc: x1.cpc.concat(x2.cpc)
    , image_url: x1.image_url
    , link: x1.link
    , imps: x1.imps.concat(x2.imps)
    , name: x1.name.concat(x2.name)
    , platform: x1.platform.concat(x2.platform)
    , spend: x1.spend.concat(x2.spend)
    , text: x1.text
    , title: x1.title
    , id: x1.id || x1.campaign_id
    , account_id:x1.account_id || ''
  })}
  const groupped = xs.map(f).reduce((res, x) => {
    return (res[kf(x)] ? { ...res, [kf(x)]: g(res[kf(x)], x) } : { ...res, [kf(x)]: x })
  }, {})

  return Object.entries(groupped).map(x => sortInside(x[1], sortField, isSortAsc))
  // return xs.flatMap((x) => {
  //   console.log(x)
  //   return [x]
  // })
}

const DefaultResultsState = {
  info: 'Choose filtering options and press Search',
  progress: {},
  items: [],
  selection: new Selection({
    onSelectionChanged: () => {
      store.dispatch(({ type: 'onAdSelect' }));
    },
  }),
  loading: false,
  columns: _columns,
  sortField: 'atspend',
  isSortAsc: false,
  atMetrics: {},
  lastFilters: {},
  createButtonPlatform: userSettings.getUserSettings().createButtonPlatform,
  messageBar: false,
  selectedCampaignNames: [],
}

export function resultsReducer(state = DefaultResultsState, event) {
  switch (event.type) {
    case 'resultPanelInit': {
      return state
    }
    case 'triggerSearch':
      triggerSearch(event.filters, state.sortField, state.isSortAsc)
      setTimeout(() => state.selection.setAllSelected(false), 20);
      return { ...state, info: 'Getting filtered ads.', loading: true, items: [], lastFilters: event.filters }
    case 'gotSearchResults':
      if (event.res.error) {
        return { ...state, loading: false, info: event.res.error }
      } else {
        const info = event.res.length >= 100 ? 'Found 100+ ads.' : `Found ${event.res.length} ads.`
        return {
          ...state,
          loading: false,
          info,
          items: event.res,
        }
      }
    case 'searchError':
      console.log(event.err.stack)
      return { ...state, loading: false, info: event.err.message }
    case 'onColumnClick': {
      const { columns, sortField, isSortAsc } = _onColumnClick(event.item, state.columns)
      setTimeout(() => store.dispatch({ type: 'triggerSearch', filters: state.lastFilters }), 20)
      return { ...state, columns, sortField, isSortAsc }
    }
    case 'onColumnExpand': {
      const { columnKey } = event;
      const columns = state.columns.map((column) => {
        if (column.key === columnKey) {
          return { ...column, isExpanded: true };
        }
        return column;
      });
      return { ...state, columns };
    }
    case 'onChangeCreatePlatform': {
      userSettings.setUserSettings({ createButtonPlatform: event.createButtonPlatform })
      return { ...state, items: [...state.items], createButtonPlatform: event.createButtonPlatform }
    }
    case 'bulkFb': {
        let selectedAds = state.selection.getSelection()
        let platforms = selectedAds.map(item=>item.platform).flat()
        if(!platforms.includes('facebook')){
            return {
                ...state,
                messageBar: {
                    type: 0,
                    message: 'Invalid platform.',
                },
            }
        } else {
            selectedAds = state.selection.getSelection().filter(el => el.platform[0] === 'facebook')
                .map(el => el.name[0])
            const link =
                `https://spm-staging.shinez.io?` +
                `openCampaignsFromInsights` +
                `&campaigns=${selectedAds.join(',')}` + `&platform=facebook`
            window.open(link, '_blank')
            setTimeout(() => state.selection.setAllSelected(false), 20);
        return state
        }
    }
    case 'bulkTw': {
        let selectedAds = state.selection.getSelection()
                .map(el => el.name[0])
            const link =
                `https://spm-staging.shinez.io?` +
                `openCampaignsFromInsights` +
                `&campaigns=${selectedAds.join(',')}` + `&platform=twitter`
            window.open(link, '_blank')
            setTimeout(() => state.selection.setAllSelected(false), 20);
            return state
    }
      case 'saveToFile': {
          const items = state.items
          const data = [];
          items.forEach((item) =>  {
              data.push(
                  {
                      "Name": item.name[0],
                      "Image":  (item.image_url && item.image_url.length > 0 ? item.image_url[0] : (item.s3ImageUrl && item.s3ImageUrl.length > 0 ? item.s3ImageUrl[0] : null)),
                      "Text": item.text[0],
                      "Title": item.title[0],
                      "LT Clicks": item.clicks[0],
                      "LT Spend": item.atspend[0],
                      "Clicks": item.atclicks[0],
                      "CTR": item.atctr[0],
                      "Spend": item.atspend[0],
                      "CPC": item.cpc[0],

                  }
              )
          });

          let worksheet = xlsx.utils.json_to_sheet(data);

          worksheet['!cols'] = [
              { wch: 50 },
              { wch: 100 },
              { wch: 50 },
              { wch: 50 },
              { wch: 10 },
              { wch: 10 },
              { wch: 10 },
              { wch: 10 },
              { wch: 10 },
              { wch: 10 },
          ];

          let workbook = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          xlsx.writeFile(workbook, 'insights.xlsx');
          return state
      }
    case 'createCampaign': {
        if (state.createButtonPlatform === 'gemini' || state.createButtonPlatform === 'google_ads'|| state.createButtonPlatform === 'taboola'|| state.createButtonPlatform === 'outbrain') {
        const link =
          `https://spm-staging.shinez.io?` +
          `createFromInsights&platform=${state.createButtonPlatform}` +
          `&id=${event.item.id}`
        window.open(link, '_blank')
        return state
      } else {
        const link =
          `https://spm.shinez.io?` +
          `createFromInsights&platform=${state.createButtonPlatform}` +
          `&id=${event.item.id}`
        window.open(link, '_blank')
        return state
      }
    }
    case 'onAdSelect': {
      console.log('Item selected', state.selection.getSelection())
      return state
    }
    case 'closeMessageBar':
      return { ...state, messageBar: false }

    default:
      return state
  }
}
