import { createMuiTheme } from '@material-ui/core/styles'

const arcBlue = '#2d84e3'
const colorTheme = 'light'

export default createMuiTheme({
  palette: {
    type: colorTheme,
    common: {
      blue: arcBlue,
    },
    primary: {
      main: arcBlue,
    },
    secondary: {
      main: '#EFF2F5',
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    // h5: {
    //   fontWeight: 700,
    // },
  },
})
