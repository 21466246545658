import {resultsReducer} from './ResultReducers'
import {filtersReducer} from './FiltersReducers'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'

const reducers = combineReducers({
  results: resultsReducer,
  filters: filtersReducer,
})

const store = createStore(reducers, applyMiddleware(thunk))

store.dispatch({type: 'initFromQS', store})



export default store

