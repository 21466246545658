import React from 'react'
import {
  MessageBar,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  MarqueeSelection,
  ScrollablePane,
  ScrollbarVisibility,
  ConstrainMode,
  Sticky,
  StickyPositionType,
} from 'office-ui-fabric-react'

const sbWidth = 8
const sbHeight = 8
const sbBg = '#eee'
const sbThumbBg = '#b2b2b2'

class ResultsPanel extends React.Component {
  componentDidMount() {
    this.props.store.dispatch({ type: 'resultPanelInit' })
  }
  render() {
    return (
      <div className="results-panel" style={{ padding: '0px 32px' }}>
        {this.props.state.messageBar && (
          <div style={{ position: 'fixed', top: '70px', right: '32px', zIndex: 100000 }}>
            <MessageBar
              style={{ margin: '10px' }}
              messageBarType={this.props.state.messageBar.type}
              isMultiline={true}
              onDismiss={() => {
                this.props.store.dispatch({ type: 'closeMessageBar' })
              }}
              dismissButtonAriaLabel="Close"
            >
              {this.props.state.messageBar.message}
            </MessageBar>
          </div>
        )}
        <MarqueeSelection selection={this.props.state.selection}>
          <div style={{ position: 'relative', height: '88vh' }}>
            <ScrollablePane
              scrollbarVisibility={ScrollbarVisibility.auto}
              styles={{
                root: {
                  selectors: {
                    '.ms-ScrollablePane--contentContainer': {
                      scrollbarWidth: sbWidth,
                      scrollbarColor: `${sbThumbBg} ${sbBg}`,
                    },
                    '.ms-ScrollablePane--contentContainer::-webkit-scrollbar': {
                      width: sbWidth,
                      height: sbHeight,
                    },
                    '.ms-ScrollablePane--contentContainer::-webkit-scrollbar-track': {
                      background: sbBg,
                    },
                    '.ms-ScrollablePane--contentContainer::-webkit-scrollbar-thumb': {
                      background: sbThumbBg,
                    },
                  },
                },
              }}
            >
              <DetailsList
                items={this.props.state.items}
                columns={this.props.state.columns}
                selectionMode={SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                selection={this.props.state.selection}
                selectionPreservedOnEmptyClick={true}
                // checkboxVisibility={CheckboxVisibility.always}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(headerProps, defaultRender) => {
                  return (
                    <Sticky
                      stickyPosition={StickyPositionType.Header}
                      isScrollSynced={true}
                      layoutMode={DetailsListLayoutMode.justified}
                    >
                      {defaultRender({
                        ...headerProps,
                      })}
                    </Sticky>
                  )
                }}
              />
            </ScrollablePane>
          </div>
        </MarqueeSelection>
      </div>
    )
  }
}

export default ResultsPanel
