import { Component } from 'react';
import { login } from './AuthService';

class Login extends Component {
    componentDidMount() {
        login();
    }

    render() {
        return null;
    }
}

export default Login;
